<template>
  <div class="PersonalDocumentacion">
    <v-card
      flat
      :loading="cargando"
      :disabled="cargando"
    >
      <v-btn
        class="mb24 mt24"
        color="primary"
        @click="showDialogVacaciones=true"
      >
        Solicitar vacaciones &nbsp;<v-icon>add</v-icon>
      </v-btn>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="vacaciones"
        :loading="cargando"
        loading-text="Cargando..."
        v-if="vacaciones.length>=1"
      >
        <template v-slot:item.created_at_pva="{ item }">
          {{_doDiaMes(item.created_at_pva)}}
        </template>
        <template v-slot:item.desde_pva="{ item }">
          {{_doDiaMes(item.desde_pva)}}
        </template>
        <template v-slot:item.hasta_pva="{ item }">
          {{_doDiaMes(item.hasta_pva)}}
        </template>
        <template v-slot:item.regresa_pva="{ item }">
          {{_doDiaMes(item.regresa_pva)}}
        </template>
        <template v-slot:item.dias_pva="{ item }">
          {{item.dias_pva}} {{item.dias_pva==1?'día':'días'}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.estatus_pva == 'solicitado'"
            icon
            @click="showDeleteDialog=item.id_pva"
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="showDialogVacaciones"
      persistent
      width="400"
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title>Solicitud de vacaciones</v-card-title>
        <v-card-text>
          <span>Tienes {{vacacionesDisponibles}} días disponibles</span>
          <date-picker
            class="mt16"
            label="Inicio de tus vacaciones"
            v-model="vacacion.desde_pva"
            sinceToday
            addDays="4"
            @change="_calcularDiasVacaciones"
          />
          <date-picker
            label="Último día de vacaciones"
            v-model="vacacion.hasta_pva"
            sinceToday
            addDays="4"
            @change="_calcularDiasVacaciones"
          />
        </v-card-text>
        <div v-if="vacacion.desde_pva && vacacion.hasta_pva">
          <v-divider />
          <v-list>
            <v-subheader>Resumen</v-subheader>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>schedule</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{diasSolicitados}} días solicitados
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>check_circle_outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Te quedarán {{vacacionesDisponibles-diasSolicitados}} días disponibles
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>update</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="nowrap">Regresas a laborar el {{_doDiaMes(vacacion.regresa_pva)}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="
            showDialogVacaciones=false;"
            text
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!canSave"
            :loading="cargando"
            @click="_saveVacaciones"
          >
            Enviar solicitud
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog
      :showDialog="showDeleteDialog?true:false"
      :loading="cargando"
      errorMessage="¿Estás seguro deseas eliminar esta solicitud de vacaciones?"
      @closed="showDeleteDialog=false"
      @confirmDelete="_deleteVacaciones"
    />
  </div>
</template>
<script>
import forms from "@/mixins/forms";
import dates from "@/mixins/dates";
import DatePicker from "@/components/forms/pickers/DatePicker.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
export default {
  name: "PersonalDocumentacion",
  mixins: [forms, dates],
  components: { DatePicker, DeleteDialog },
  data() {
    return {
      ruta: "personal/vacaciones",
      vacaciones: [],
      headers: [
        { text: "Solicitud", value: "created_at_pva" },
        { text: "Estatus", value: "estatus_pva" },
        { text: "Inician", value: "desde_pva" },
        { text: "Terminan", value: "hasta_pva" },
        { text: "Regresas", value: "regresa_pva" },
        { text: "Días", value: "dias_pva" },
        { text: "", value: "actions", align: "end" },
      ],
      showDialogVacaciones: false,
      showDeleteDialog: false,
      //inicio y termino de vacaciones
      vacacion: {
        tipo_pva: "vacaciones",
        desde_pva: null,
        hasta_pva: null,
        regresa_pva: null,
        dias_pva: 0,
      },
      canSave: false,
      anios: false,
      vacacionesDisponibles: false,
      diasSemana: false,
    };
  },
  methods: {
    async _getVacaciones(res = null) {
      try {
        if (res && "msg" in res) this.$emit("msg", res.msg);
      } catch (err) {}
      this.showDialogVacaciones = false;
      this.showDeleteDialog = false;
      this.cargando = true;
      this.canSave = false;
      try {
        const { body } = await this.$http.get(this.ruta, this.$headers);
        this.cargando = false;
        if ("vacaciones" in body) {
          this.anios = body.anios;
          this.vacaciones = body.vacaciones;
          this.vacacionesDisponibles = body.dias_disponibles;
          this.diasSemana = body.dias_semana;
        } else {
          this.$emit("msg", body.error);
        }
      } catch (err) {
        this.cargando = false;
        this._handleError(err);
      }
      //   this._getThings(this.ruta + this.persona, "vacaciones");
    },
    async _calcularDiasVacaciones() {
      const { desde_pva, hasta_pva } = this.vacacion;
      if (!desde_pva || !hasta_pva) {
        this.diasSolicitados = 0;
        return null;
      }
      try {
        this.cargando = true;
        const { body } = await this.$http.post(
          "personal/diasvacaciones/",
          {
            inicio: desde_pva,
            fin: hasta_pva,
          },
          this.$headers
        );
        this.cargando = false;
        if (body.allow) {
          this.canSave = true;
          this.diasSolicitados = body.dias_solicitados;
          this.vacacion.dias_pva = body.dias_solicitados;
          this.vacacion.regresa_pva = body.fecha_regreso;
        } else {
          this.canSave = false;
          this.vacacion.desde_pva = null;
          this.vacacion.hasta_pva = null;
          this.vacacion.regresa_pva = null;
          this.vacacion.dias_pva = 0;
          this.$emit("msg", body.error);
        }
        return null;
      } catch (err) {
        this.cargando = false;
        const { body } = err;
        if (!body.allow) {
          this.canSave = false;
          this.vacacion.desde_pva = null;
          this.vacacion.hasta_pva = null;
          this.vacacion.regresa_pva = null;
          this.diasSolicitados = 0;
          this.$emit("msg", body.error);
        }
        return "0";
        // this._handleError(err);
      }
    },
    _saveVacaciones() {
      const data = { vacacion: this.vacacion };
      this._saveAll(this.ruta, data, "id", "_getVacaciones");
    },
    _deleteVacaciones(a) {
      this._deleteAll(
        this.ruta + "/" + this.showDeleteDialog,
        "msg",
        "_getVacaciones"
      );
    },
  },
  created() {
    this._getVacaciones();
  },
};
</script>
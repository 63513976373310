<template>
  <v-dialog
    persistent
    dark
    v-model="showDialog"
    width="500"
  >
    <v-card
      :color="$error"
      :loading="loading"
      :disabled="loading"
    >
      <template slot="progress">
        <v-progress-linear
          color="white"
          indeterminate
        />
      </template>
      <v-card-title v-text="errorMessage" />
      <v-card-text v-if="errorMessageDetail">
        {{errorMessageDetail}}
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          v-text="'Cancelar'"
          @click="_closeDialog"
        />
        <v-btn
          light
          :loading="loading"
          @click="$emit('confirmDelete',Date.now())"
        >
          Si, eliminar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    showDialog: Boolean,
    loading: Boolean,
    errorMessage: String,
    errorMessageDetail: String,
  },
  methods: {
    _closeDialog() {
      this.showDialog = false;
      this.$emit("closed", Date.now());
    },
  },
};
</script>